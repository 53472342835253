import {request} from './request'
import baseUrlObj from "./baseURL"
const systemNumber = baseUrlObj.systemNumber

// 获取期刊列表 /ewi/guest/Journal/PageJournalList
export function pageJournalList(isHome,page,size,title,typeId){
  return request({
    url: '/ewi/guest/Journal/PageJournalList',
    method: 'post',
    data: {
      isHome,
      page,
      size,
      title,
      typeId,
      systemNumber: systemNumber,
    }
  })
}

// 获取期刊类型列表 /ewi/guest/Journal/GetJournalTypeList
export function getJournalTypeList(){
  return request({
    url: '/ewi/guest/Journal/GetJournalTypeList',
    method: 'get',
    params: {
      systemNumber: systemNumber,
    }
  })
}

// 获取单个期刊信息 /ewi/guest/Journal/GetJournalInfo
export function getJournalInfo(id){
  return request({
    url: '/ewi/guest/Journal/GetJournalInfo',
    method: 'get',
    params: {
      id,
      systemNumber: systemNumber,
    }
  })
}
